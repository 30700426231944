import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from './firebase-config';
import { initializeErrorLogger } from './utils/errorLogger';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Subscribe to auth changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          const token = await user.getIdToken(); // Retrieve the auth token
          localStorage.setItem('authToken', token); // Store it in localStorage
        } catch (error) {
          console.error('Error fetching auth token:', error);
          localStorage.removeItem('authToken');
        }
      } else {
        setCurrentUser(null);
        localStorage.removeItem('authToken'); // Remove authToken if user logs out
      }
      setLoading(false); // Set loading to false once the user state is established
      
      // Initialize error logger with current auth state
      initializeErrorLogger(auth);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  // To make the currentUser available to all components
  const value = {
    currentUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};