import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import the useAuth hook
import { loadStripe } from '@stripe/stripe-js';
import './Pricing.css';
import './Home.css';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore functions

function PricingPlans({ isMonthly, setIsMonthly }) {
    const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

    const { currentUser } = useAuth(); // Retrieve the current user state
    const navigate = useNavigate();
    const [region, setRegion] = useState('EU'); // Default to EU

    // Separate state for Starter button
    const [isLoadingStarter, setIsLoadingStarter] = useState(false);
    const [buttonTextStarter, setButtonTextStarter] = useState('Try Now');

    // State to track if the user has already trialed
    const [hasTrialed, setHasTrialed] = useState(false);

    // Separate state for Pro button
    const [isLoadingPro, setIsLoadingPro] = useState(false);
    const [buttonTextPro, setButtonTextPro] = useState('Start Now');

    const db = getFirestore(); // Initialize Firestore

    useEffect(() => {
        // Fetch user's region on mount
        const fetchRegion = async () => {
            try {
                const response = await fetch('/.netlify/functions/get-region');
                const data = await response.json();
                if (data.country_code === 'US') {
                    setRegion('US');
                } else {
                    setRegion('EU');
                }
            } catch (error) {
                console.error('Error fetching region:', error);
                // Default to EU on error
                setRegion('EU');
            }
        };

        fetchRegion();
    }, []);

    async function handleStartNow(planType) {
        if (planType === 'starter') {
            if (!currentUser) {
                navigate('/login');
                return;
            }

            try {
                // Check if the user document exists, if not create it
                const userDocRef = doc(db, 'users', currentUser.uid);
                const userDoc = await getDoc(userDocRef);
                
                if (!userDoc.exists()) {
                    // Initialize user document if it doesn't exist
                    try {
                        await setDoc(userDocRef, {
                            email: currentUser.email,
                            createdAt: new Date(),
                            hasTrialed: false
                        });
                    } catch (error) {
                        console.error('Error creating user document:', error);
                        setButtonTextStarter('Error, please try again');
                        return;
                    }
                }

                const userData = userDoc.exists() ? userDoc.data() : { hasTrialed: false };
                if (userData.hasTrialed) {
                    setHasTrialed(true);
                    setButtonTextStarter('Trial used');
                    return;
                }
            } catch (error) {
                console.error('Error checking trial status:', error);
                setButtonTextStarter('Error, please try again');
                return;
            }
        }

        if (!currentUser) {
            navigate('/login');
            return;
        }

        // Determine which button's state to update
        const isProPlan = planType === 'monthly' || planType === 'yearly';

        if (isProPlan) {
            setIsLoadingPro(true);
            setButtonTextPro('Loading...');
        } else if (planType === 'starter') {
            setIsLoadingStarter(true);
            setButtonTextStarter('Loading...');
        }

        const timeout = setTimeout(() => {
            if (isProPlan) {
                setButtonTextPro('Failed, please contact us');
            } else if (planType === 'starter') {
                setButtonTextStarter('Failed, please contact us');
            }
        }, 10000);

        try {
            const authToken = localStorage.getItem('authToken');

            const response = await fetch('/.netlify/functions/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    uid: currentUser.uid,
                    plan: planType, // 'starter', 'monthly', or 'yearly'
                    referrer: window.location.origin,
                    isMonthly,
                    region,
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create checkout session');
            }

            const { sessionId } = await response.json();
            if (!sessionId) {
                throw new Error('No sessionId returned');
            }

            // Redirect to Stripe Checkout
            const stripe = await loadStripe(stripeKey); // Replace with your actual publishable key
            await stripe.redirectToCheckout({ sessionId });
        } catch (error) {
            console.error('Error creating checkout session:', error);
            if (isProPlan) {
                setButtonTextPro('Failed, please contact us');
                setIsLoadingPro(false);
            } else if (planType === 'starter') {
                setButtonTextStarter('Failed, please contact us');
                setIsLoadingStarter(false);
            }
            clearTimeout(timeout);
        }
    };

    return (
        <>
            <div className="homeContainerMain">
                <div className="paymentScheduleContent">
                    <div className="toggleContainer">
                        <button
                            className={`toggleOption ${isMonthly ? 'active' : ''}`}
                            onClick={() => setIsMonthly(true)}
                            aria-label="Select Monthly Plan"
                        >
                            Monthly
                        </button>
                        <button
                            className={`toggleOption ${!isMonthly ? 'active' : ''}`}
                            onClick={() => setIsMonthly(false)}
                            aria-label="Select Yearly Plan"
                        >
                            Yearly
                        </button>
                    </div>
                </div>
            </div>

            <div className="homeContainerMain">
                <div className="homeContainerCardsContent">
                    {/* Starter Plan */}
                    <div className="subscriptionCard starter">
                        <div>
                            <h2 className="normalText" style={{ fontSize: '20px', marginBottom: '-18px', marginLeft: '28px', marginTop: '25px' }}>Starter</h2>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                <p className="boldText" style={{ fontSize: '28px', marginBottom: '5px', marginLeft: '28px' }}>
                                    Free
                                </p>
                                <p className="normalText" style={{ fontSize: '18px', marginLeft: '10px', marginBottom: '7px', opacity: '0.75' }}>
                                    2 weeks
                                </p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <button
                                    className="otherButton"
                                    onClick={() => handleStartNow('starter')}
                                    disabled={isLoadingStarter || hasTrialed}
                                    aria-label="Start Free Trial"
                                >
                                    {hasTrialed ? 'Trial used' : buttonTextStarter}
                                </button>
                            </div>
                            <p className="termsText">
                                By proceeding you agree to our <a href="/terms-of-service" style={{ marginLeft: '2px' }}>terms of service</a>.
                            </p>
                            <p className="normalText" style={{ fontSize: '16px', marginLeft: '28px', marginRight: '28px' }}>
                                Discover what you can do with Optic:
                            </p>
                            <ul style={{ listStyleType: 'none', padding: 0, marginLeft: '28px', marginTop: '-7px' }}>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Access for 2 weeks
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Unlimited Generations
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Try out all the features
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Pro Plan */}
                    <div className="subscriptionCard pro">
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                <h2 className="normalText" style={{ fontSize: '20px', marginBottom: '-18px', marginLeft: '28px', marginTop: '25px' }}>Pro</h2>
                                {!isMonthly && (
                                    <p className="normalText" style={{ fontSize: '17px', marginLeft: '10px', marginBottom: '-17.5px', opacity: '0.5' }}>
                                        (12.5% off) billed yearly
                                    </p>
                                )}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                <p className="boldText" style={{ fontSize: '28px', marginBottom: '5px', marginLeft: '28px' }}>
                                    {region === 'US' ? `$${isMonthly ? '40' : '35'}` : `${isMonthly ? '40' : '35'}€`}
                                </p>
                                <p className="normalText" style={{ fontSize: '18px', marginLeft: '10px', marginBottom: '7px', opacity: '0.75' }}>
                                    / month
                                </p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <button
                                    className="subscriptionButton"
                                    onClick={() => handleStartNow(isMonthly ? 'monthly' : 'yearly')}
                                    disabled={isLoadingPro}
                                    aria-label="Start Pro Subscription"
                                >
                                    {buttonTextPro}
                                </button>
                            </div>
                            <p className="termsText">
                            By proceeding you agree to our <a href="/terms-of-service" style={{ marginLeft: '2px' }}>terms of service</a>.
                            </p>
                            <p className="normalText" style={{ fontSize: '16px', marginLeft: '28px', marginRight: '28px' }}>
                                The complete package:
                            </p>
                            <ul style={{ listStyleType: 'none', padding: 0, marginLeft: '28px' }}>
                                <li style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Unlimited Generations
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Commercial Terms
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Access to all features
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Blender plugin
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Priority support
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Enterprise Plan */}
                    <div className="subscriptionCard enterprise">
                        <div>
                            <h2 className="normalText" style={{ fontSize: '20px', marginBottom: '-18px', marginLeft: '28px', marginTop: '25px' }}>Enterprise</h2>
                            <p className="boldText" style={{ fontSize: '28px', marginBottom: '5px', marginLeft: '28px' }}>
                                Request
                            </p>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <button className="otherButton" onClick={() => {
                                    navigate('/contact');
                                }} aria-label="Contact Sales">
                                    Contact Sales
                                </button>
                            </div>
                            <p className="normalText" style={{ fontSize: '16px', marginLeft: '28px', marginRight: '28px' }}>
                                Unlock advanced enterprise features such as:
                            </p>
                            <ul style={{ listStyleType: 'none', padding: 0, marginLeft: '28px' }}>
                                <li style={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Isolated cloud instance
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Local installation
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Custom configurations
                                    </p>
                                </li>
                                <li style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="checkIcon" style={{ marginTop: '7px' }}></div>
                                    <p className="normalText" style={{ marginBottom: '8px', fontSize: '16px', marginLeft: '10px' }}>
                                        Enterprise support
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PricingPlans;