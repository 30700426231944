import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from './firebase-config'; // Import Firebase auth
import { onAuthStateChanged, signOut } from 'firebase/auth'; // Import necessary Firebase methods
import './Home.css';
import './NavBar.css';
import { db } from './firebase-config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';

function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null); // State to hold user info
  const [isSubscribed, setIsSubscribed] = useState(false); // State to hold subscription status
  const mobileMenuRef = useRef(null);
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState(null);
  const { currentUser, loading } = useAuth();

  const fetchUserDetails = async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
    
      if (userSnap.exists()) {
        return userSnap.data();
      } else {
        // Initialize user document if it doesn't exist
        const userData = {
          email: currentUser.email,
          createdAt: new Date(),
          hasTrialed: false
        };
        try {
          await setDoc(userRef, userData);
          return userData;
        } catch (error) {
          console.error("Error creating user document:", error);
          return {};
        }
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      return {};
    }
  };

  useEffect(() => {
    // Ensure currentUser exists and auth is initialized before fetching details
    if (currentUser && !loading) {
      fetchUserDetails(currentUser.uid)
        .then(userDetails => {
          if (userDetails.stripeCustomerId) {
            setCustomerId(userDetails.stripeCustomerId);
          }
        })
        .catch(error => {
          console.error("Failed to fetch user details", error);
        });
    }
  }, [currentUser, loading]);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        await checkSubscriptionStatus(currentUser);
      } else {
        setIsSubscribed(false);
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Optionally, navigate to home or login page after logout
      // navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  // Function to check subscription status
  const checkSubscriptionStatus = async (currentUser) => {
    try {
      const response = await fetch('/.netlify/functions/get-subscription-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: currentUser.uid,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch subscription status');
      }

      const data = await response.json();
      setIsSubscribed(data.isSubscribed);
    } catch (error) {
      console.error('Error checking subscription status:', error);
      setIsSubscribed(false);
    }
  };

  // Function to manage subscription
  const manageSubscription = async () => {
    if (!user) return;

    try {
      const response = await fetch('/.netlify/functions/create-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId }), // Previously obtained customer ID
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { url } = await response.json();

      // Redirect the user to the Customer Portal
      window.location.href = url;
    } catch (error) {
      console.error('Error redirecting to customer portal:', error);
    }
  };

  return (
    <header className="navContainer">
      <div className="navBar">
        <div className="left-icons">
          <button
            className="menuIcon show-on-mobile"
            onClick={toggleMobileMenu}
            aria-label="Toggle Navigation Menu"
          >
            <div className="hamburgerIcon">
            </div>
          </button>
          <Link to="/" className="udinLogo" aria-label="UDIN Home">
            {/* If you have a logo image, include it here with alt text */}
            {/* <img src="/path/to/logo.png" alt="UDIN Logo" /> */}
          </Link>
        </div>
        <nav className="navLinks hide-on-mobile" aria-label="Main Navigation">
          <div className="navTitles">
            <Link className="navLink" to="/tools">
              Company
            </Link>
            <Link className="navLink" to="/optic-tool">
              Optic
            </Link>
            <a
              className="navLink"
              href="https://linktr.ee/udinbv"
              target="_blank"
              rel="noopener noreferrer"
            >
              Socials
            </a>
          </div>
        </nav>
        <Link className="opticButton hide-on-mobile" to="/optic">
          Open App
        </Link>
      </div>
      <div className="dropdownMenu">
        <div className="maxWidthWrapper">
          <div className="alignerContainer"></div>
          <div className="dropdownMenuContent">
            <div className="dropdownColumn" style={{ width: '100px' }}>
              <Link
                className="dropdownLink"
                to="/tools"
                style={{ marginLeft: '31px' }}
              >
                Tools
              </Link>
              <Link
                className="dropdownLink"
                to="/about-us"
                style={{ marginLeft: '31px' }}
              >
                About Us
              </Link>
              <Link
                className="dropdownLink"
                to="/contact"
                style={{ marginLeft: '31px' }}
              >
                Contact
              </Link>
            </div>
            <div className="dropdownColumn" style={{ width: '150px' }}>
              <Link
                className="dropdownLink"
                to="/optic-tool"
                style={{ marginLeft: '58px' }}
              >
                Features
              </Link>
              <Link
                className="dropdownLink"
                to="/pricing"
                style={{ marginLeft: '58px' }}
              >
                Pricing
              </Link>
              <Link
                className="dropdownLink"
                to="/gallery"
                style={{ marginLeft: '58px' }}
              >
                Gallery
              </Link>
            </div>
            <div className="dropdownColumn" style={{ width: '100px' }}>
              <a
                className="dropdownLink"
                href="https://www.instagram.com/udin.bv"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '3px' }}
              >
                Instagram
              </a>
              <a
                className="dropdownLink"
                href="https://www.linkedin.com/company/udin-bv"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '3px' }}
              >
                LinkedIn
              </a>
              <a
                className="dropdownLink"
                href="https://www.youtube.com/@udin_official/videos"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '3px' }}
              >
                YouTube
              </a>
            </div>
          </div>
          {user ? (
            <div className="profileContainer">
              <div
                className="normalText"
                style={{
                  fontSize: '14px',
                  marginTop: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                }}
              >
                {user.displayName || 'User'}
              </div>
              <div
                className="normalText"
                style={{
                  fontSize: '12px',
                  marginTop: '5px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                }}
              >
                {user.email}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                {isSubscribed && (
                  <button className="profileButton" 
                    onClick={manageSubscription}
                    style={{ width: '100px', height: '25px', marginTop: '5px' }}
                  >
                    Subscription
                  </button>
                )}
                <button className="profileButton" 
                  onClick={handleLogout}
                  style={{ height: '25px', marginTop: '5px' }}
                >
                  Logout
                </button>
              </div>
            </div>
          ) : (
            <div className="profileContainer">
              <button
                className="profileButton"
                style={{ fontSize: '13px', padding: '2px 15px' }}
                onClick={() => navigate('/login')}
              >
                Login
              </button>
            </div>
          )}
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="mobileMenuOverlay hide-on-desktop">
          <div className="mobileMenuContent" ref={mobileMenuRef}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                className="boldText"
                style={{
                  marginLeft: '25px',
                  marginBottom: '10px',
                  fontSize: '36px',
                }}
              >
                Profile
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  className="closeMenuIcon"
                  onClick={toggleMobileMenu}
                  aria-label="Close Menu"
                >
                  &times;
                </button>
              </div>
            </div>
            {user && (
              <div
                className="profileContainer"
                style={{ marginLeft: '25px', width: '220px' }}
              >
                <div
                  className="mediumText"
                  style={{
                    fontSize: '16px',
                    marginTop: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    marginLeft: '2px',
                  }}
                >
                  {user.displayName || 'User'}
                </div>
                <div
                  className="normalText"
                  style={{
                    fontSize: '14px',
                    marginTop: '5px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    marginLeft: '2px',
                  }}
                >
                  {user.email}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap:"10px" }}>
                  
                  <button
                    className="profileButton"
                    style={{
                      fontSize: '16px',
                      padding: '5px 15px',
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                  {isSubscribed && (
                    <button
                      className="profileButton"
                      style={{
                        fontSize: '16px',
                        padding: '5px 15px',
                        width: '120px'
                      }}
                      onClick={manageSubscription}
                    >
                      Subscription
                    </button>
                  )}
                </div>
              </div>
            )}
            {!user && (
              <button
                className="profileButton"
                style={{
                  fontSize: '16px',
                  padding: '5px 15px',
                  marginLeft: '25px',
                }}
                onClick={() => navigate('/login')}
              >
                Login
              </button>
            )}
            <nav className="mobileMenuLinks" aria-label="Mobile Navigation">
              <div
                className="boldText"
                style={{
                  marginLeft: '25px',
                  marginBottom: '15px',
                  fontSize: '36px',
                }}
              >
                Company
              </div>
              <Link
                className="mobileNavLink"
                to="/tools"
                onClick={toggleMobileMenu}
              >
                Tools
              </Link>
              <Link
                className="mobileNavLink"
                to="/about-us"
                onClick={toggleMobileMenu}
              >
                About Us
              </Link>
              <Link
                className="mobileNavLink"
                to="/contact"
                onClick={toggleMobileMenu}
              >
                Contact
              </Link>
              <div
                className="boldText"
                style={{
                  marginLeft: '25px',
                  marginBottom: '15px',
                  marginTop: '25px',
                  fontSize: '36px',
                }}
              >
                Optic
              </div>
              <Link
                className="mobileNavLink"
                to="/optic-tool"
                onClick={toggleMobileMenu}
              >
                Features
              </Link>
              <Link
                className="mobileNavLink"
                to="/pricing"
                onClick={toggleMobileMenu}
              >
                Pricing
              </Link>
              <Link
                className="mobileNavLink"
                to="/gallery"
                onClick={toggleMobileMenu}
              >
                Gallery
              </Link>
              <div
                className="boldText"
                style={{
                  marginLeft: '25px',
                  marginBottom: '15px',
                  marginTop: '25px',
                  fontSize: '36px',
                }}
              >
                Socials
              </div>
              <a
                className="mobileNavLink"
                href="https://www.instagram.com/udin.bv"
                target="_blank"
                rel="noopener noreferrer"
                onClick={toggleMobileMenu}
              >
                Instagram
              </a>
              <a
                className="mobileNavLink"
                href="https://www.linkedin.com/company/udin-bv"
                target="_blank"
                rel="noopener noreferrer"
                onClick={toggleMobileMenu}
              >
                LinkedIn
              </a>
              <a
                className="mobileNavLink"
                href="https://www.youtube.com/@udin_official/videos"
                target="_blank"
                rel="noopener noreferrer"
                onClick={toggleMobileMenu}
                style={{ marginBottom: '50px' }}
              >
                YouTube
              </a>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
}

export default NavBar;