// Store the original console.error function
const originalConsoleError = console.error;

// List of error messages to ignore
const ignoredErrors = [
  "Warning: Using UNSAFE_componentWillMount in strict mode is not recommended and may indicate bugs in your code. See https://reactjs.org/link/unsafe-component-lifecycles for details.",
  "Connection to Indexed Database server lost. Refresh the page to try again",
  "Error polling job status, attempting to retry...",
  "Attempt 1/3 failed:",
  "Attempt 2/3 failed:",
  "Retry 1/3 after",
  "Retry 2/3 after"
];

// Function to send error to Telegram
const sendToTelegram = async (error, extraInfo = {}) => {
  try {
    const errorMessage = error instanceof Error ? error.stack || error.message : error?.toString();
    
    // Check if the error is in the ignore list
    if (ignoredErrors.some(ignoredError => errorMessage.includes(ignoredError))) {
      // If the error is ignored, do not send a notification
      return;
    }

    const userInfo = extraInfo.user ? `User: ${extraInfo.user}\n` : '';
    const locationInfo = extraInfo.location ? `Location: ${extraInfo.location}\n` : '';
    const timestamp = new Date().toISOString();

    await fetch('/.netlify/functions/send-telegram-notification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        error: errorMessage,
        context: `${userInfo}${locationInfo}Time: ${timestamp}`,
        timestamp,
      }),
    });
  } catch (telegramError) {
    // Use the original console.error to avoid infinite loop
    originalConsoleError('Failed to send error to Telegram:', telegramError);
  }
};

// Initialize the global error logger
export const initializeErrorLogger = (auth) => {
  // Override console.error
  console.error = (...args) => {
    // Call the original console.error first
    originalConsoleError.apply(console, args);

    // Get current user info if available
    const userInfo = auth?.currentUser ? {
      user: `${auth.currentUser.email || auth.currentUser.uid}`,
      location: window.location.pathname
    } : {
      location: window.location.pathname
    };

    // Send to Telegram
    sendToTelegram(args[0], userInfo);
  };

  // Add global error handler for uncaught exceptions
  window.addEventListener('error', (event) => {
    const userInfo = auth?.currentUser ? {
      user: `${auth.currentUser.email || auth.currentUser.uid}`,
      location: window.location.pathname
    } : {
      location: window.location.pathname
    };

    sendToTelegram(event.error || event.message, userInfo);
  });

  // Add handler for unhandled promise rejections
  window.addEventListener('unhandledrejection', (event) => {
    const userInfo = auth?.currentUser ? {
      user: `${auth.currentUser.email || auth.currentUser.uid}`,
      location: window.location.pathname
    } : {
      location: window.location.pathname
    };

    sendToTelegram(event.reason, userInfo);
  });
}; 