import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const RequireAuth = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      if (currentUser) {
        const subscribed = await checkSubscription(currentUser.uid);
        setIsSubscribed(subscribed);
      } else {
        console.log('No current user. Stopping auth check.');
      }
      setIsLoading(false);
    };

    fetchSubscription();
  }, [currentUser]);

  const checkSubscription = async (uid) => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      console.error('No authToken found');
      return false;
    }
    try {
      const response = await fetch('/.netlify/functions/get-subscription-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ uid }),
      });

      if (!response.ok) {
        console.error('Failed to fetch subscription status:', response.statusText);
        return false;
      }

      const data = await response.json();
      return data.isSubscribed;

    } catch (error) {
      console.error('Error checking subscription status:', error);
      return false;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isSubscribed) {
    return <Navigate to="/pricing" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;